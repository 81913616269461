import sift from 'sift'

import buildQueryActivities from '@/plugins/axios-interceptors/build-query-activities.js'

const isBackupAppInterceptor = ({ $axios }) => {
  $axios.defaults.adapter = function (config) {
    let data

    if (config.url === 'current-user' || config.url === 'login') {
      data = {
        user: window.planfredOffline.currentuser,
      }
    } else if (config.url.match(/users\/[a-zA-Z0-9]*/)) {
      data = []
    } else if (config.url === 'projects') {
      data = [window.planfredOffline.project]
    } else if (config.url.match(/projects\/[a-zA-Z0-9]*/)) {
      // /projects/58b70a51e7b6b69c746e8df3
      data = window.planfredOffline.project
    } else if (config.url === 'plans' && config.params.projectid) {
      // /plans?projectid=581e0797cb495f0e200e9af0
      data = window.planfredOffline.plans
    } else if (config.url.match(/plans\/[a-zA-Z0-9]*/)) {
      // /plans/52f613570385b300006905ce
      if (config.url.match(/plans\/[a-zA-Z0-9]{24}\/activities/)) {
        const pathParams = config.url.split('/').filter(i => i)
        const planId = pathParams[1]

        // /tasks/581e0797cb495f0e200e9af0/activities
        data = window.planfredOffline.activities.filter(sift({
          $or: [
            {
              'log.plan._id': planId,
              action: {
                $in: [
                  'getplanrevisionpdf',
                  'getplanrevisiondwg',
                  'getapprovalcommentattachment',
                  'patchplantrash',
                ],
              },
            },
            {
              action: 'sendrevisionplan',
              'log.revisionsSent': {
                $elemMatch: {
                  'plan._id': planId,
                },
              },
            },
          ],
        }))
      } else {
        const planId = config.url.split('/')[1]
        data = window.planfredOffline[`plan_${planId}`]
      }
    } else if (config.url === 'documents' && config.params.projectid) {
      // /documents?projectid=581e0797cb495f0e200e9af0
      data = window.planfredOffline.documents
    } else if (config.url.match(/documents\/[a-zA-Z0-9]*/)) {
      // /documents/52f613570385b300006905ce
      if (config.url.match(/documents\/[a-zA-Z0-9]{24}\/activities/)) {
        const pathParams = config.url.split('/').filter(i => i)
        const documentId = pathParams[1]

        // /tasks/581e0797cb495f0e200e9af0/activities
        data = window.planfredOffline.activities.filter(sift({
          $or: [
            {
              'log.document._id': documentId,
              action: {
                $in: [
                  'getdocumentrevision',
                  'getdocumentapprovalcommentattachment',
                  'patchdocumenttrash',
                ],
              },
            },
            {
              action: 'sendrevisiondoc',
              'log.revisionsSent': {
                $elemMatch: {
                  'document._id': documentId,
                },
              },
            },
          ],
        }))
      } else {
        const documentId = config.url.split('/')[1]
        data = window.planfredOffline[`document_${documentId}`]
      }
    } else if (config.url === 'tasks' && config.params.projectid) {
      // /tasks?projectid=581e0797cb495f0e200e9af0
      data = window.planfredOffline.tasks
    } else if (config.url === 'tasks/latest-by-user' && config.params.projectid) {
      // /tasks/latest-by-user

      const taskList = window.planfredOffline.tasks && window.planfredOffline.tasks.list ? window.planfredOffline.tasks.list : []
      const taskListFilteredAndSorted = taskList
        .filter(task => {
          const reqUser = window.planfredOffline.currentuser

          return task.projectId === config.params.projectid && task.creatorUserId === reqUser._id
        })
        .sort((a, b) => new Date(b.created) - new Date(a.created))

      data = taskListFilteredAndSorted[0]
    } else if (config.url.match(/tasks\/[a-zA-Z0-9]{24}/)) {
      const pathParams = config.url.split('/').filter(i => i)
      const taskId = pathParams[1]

      if (config.url.match(/tasks\/[a-zA-Z0-9]{24}\/activities/)) {
        // /tasks/581e0797cb495f0e200e9af0/activities
        data = window.planfredOffline.activities.filter(sift({
          'log.task._id': taskId,
          'log.comment._id': {
            $exists: false,
          },
        }))
      } else if (config.url.match(/tasks\/[a-zA-Z0-9]{24}\/comments\/[a-zA-Z0-9]{24}\/activities/)) {
        // /tasks/581e0797cb495f0e200e9af0/comments/581e0797cb495f0e200e9aaa/activities
        const commentId = pathParams[3]
        data = window.planfredOffline.activities.filter(sift({
          'log.comment._id': commentId,
        }))
      } else {
        // /tasks/581e0797cb495f0e200e9af0
        data = window.planfredOffline[`task_${taskId}`]
      }
    } else if (config.url === 'photos' && config.params.projectid) {
      // /photos?projectid=581e0797cb495f0e200e9af0
      data = window.planfredOffline.photos
    } else if (config.url === 'photos/latest-by-user' && config.params.projectid) {
      // /tasks/latest-by-user

      const photoList = window.planfredOffline.photos && window.planfredOffline.photos.list ? window.planfredOffline.photos.list : []
      const photoListFilteredAndSorted = photoList
        .filter(photo => {
          const reqUser = window.planfredOffline.currentuser

          return photo.projectId === config.params.projectid && photo.creatorUserId === reqUser._id
        })
        .sort((a, b) => new Date(b.created) - new Date(a.created))

      data = photoListFilteredAndSorted[0]
    } else if (config.url.match(/photos\/[a-zA-Z0-9]{24}/)) {
      const pathParams = config.url.split('/').filter(Boolean)
      const photoId = pathParams[1]

      if (config.url.match(/photos\/[a-zA-Z0-9]{24}\/activities/)) {
        // /photos/581e0797cb495f0e200e9af0/activities
        data = window.planfredOffline.activities.filter(sift({
          'log.photo._id': photoId,
        }))
      } else {
        // /photos/581e0797cb495f0e200e9af0
        const photoList = window.planfredOffline.photos && window.planfredOffline.photos.list ? window.planfredOffline.photos.list : []
        data = photoList.find(p => p._id === photoId)
      }
    } else if (config.url === 'pf-files' && config.params.projectid) {
      // /pf-files?projectid=581e0797cb495f0e200e9af0
      data = window.planfredOffline.pfFiles
    } else if (config.url === 'location-layers' && config.params.projectid) {
      data = window.planfredOffline['location-layers']
    } else if (config.url === 'filters' && config.params.projectid && config.params.filterkeys) {
      // /filters?projectid=581e0797cb495f0e200e9af0&filterkeys=tasksListWebMain,tasksListWebDone
      // mock server side filters in backup app with empty object
      data = []
    } else if (config.url === 'filters' && config.params.projectid) {
      // /filters?projectid=581e0797cb495f0e200e9af0
      // mock server side filters in backup app with empty object
      data = []
    } else if (config.url.match(/activities\/[a-zA-Z0-9]*/)) {
      // /activities/52f613570385b300006905ce
      const activityId = config.url.split('/')[1]
      data = window.planfredOffline.activities.find(activity => activity._id === activityId)
      // New activities query with POST request
    } else if (config.url.match(/activities?/) && config.method === 'post') {
      const reqUser = window.planfredOffline.currentuser
      const project = window.planfredOffline.project
      const reqQuery = JSON.parse(config.data)

      const query = buildQueryActivities({
        reqUser,
        project,
        reqQuery,
      })
      data = window.planfredOffline.activities.filter(sift(query))
      // Old activities query with GET request for backward compatibility
    } else if (config.url.match(/activities?/) && config.method === 'get') {
      // /activities?action=all&endDate=Wed+Apr+19+2017+23:59:59+GMT%2B0200&projectid=58b70a51e7b6b69c746e8df3&startDate=Wed+Apr+12+2017+00:00:00+GMT%2B0200&timeout=%7B%7D
      const userId = config.params.userid
      const action = config.params.action
      const startDate = config.params.startDate || new Date(0).toISOString()
      const endDate = config.params.endDate || new Date().toISOString()
      const targetedUserId = config.params.targeteduserid

      const query = {
        date: {
          $gte: new Date(startDate).toISOString(),
          $lte: new Date(endDate).toISOString(),
        },
      }

      if (action === 'uploads') {
        query.$or = [
          { action: 'postplan' },
          { action: 'postplanrevision' },
          { action: 'postdocument' },
          { action: 'postdocumentrevision' },
        ]
      } else if (action === 'downloads') {
        query.action = {
          $in: [
            'getplanrevisionpdf',
            'getplanrevisiondwg',
            'getdocumentrevision',
            'getdocumentapprovalcommentattachment',
            'getapprovalcommentattachment',
          ],
        }

        query['log.downloadSeriesPosition'] = {
          $in: [
            'latest',
            'first',
          ],
        }
      } else if (action === 'tasks') {
        query.action = {
          $in: [
            'posttaskcomment',
            'patchtaskfiles',
            'patchtaskassigneeuserid',
            'patchtaskcommentmessage',
            'patchtaskcommentfiles',
            'patchtaskdescription',
            'patchtaskduedate',
            'patchtasklocation',
            'posttaskpublished',
            'patchtasktitle',
            'patchtasktrashed',
            'patchtasktype',
          ],
        }
      } else if (action === 'photos') {
        query.action = {
          $in: [
            'postphoto',
            'patchphototitle',
            'patchphotodescription',
            'patchphotoauthor',
            'patchphotolocation',
            'patchphototrashed',
            'patchphotoalbum',
            'patchphotofile',
          ],
        }
      } else if (action === 'sent') {
        query.$or = [
          { action: 'sendrevisiondoc' },
          { action: 'sendrevisionplan' },
        ]
      } else if (action === 'receive') {
        query.$or = [
          { action: 'sendrevisiondoc' },
          { action: 'sendrevisionplan' },
        ]
      } else if (action === 'approval') {
        query.$or = [
          {
            action: {
              $in: [
                'postapproval',
                'postdocumentapproval',
                'putapproveplanrevision',
                'putapprovedocumentrevision',
                'patchplanrevisionapproval',
                'postapprovalcomment',
                'postdocumentapprovalcomment',
                'putprojectparticipantcanapproverevisions',
              ],
            },
          },
          {
            action: 'getapprovalcommentattachment',
            'log.downloadSeriesPosition': {
              $in: [
                'latest',
                'first',
              ],
            },
          },
          {
            action: 'getdocumentapprovalcommentattachment',
            'log.downloadSeriesPosition': {
              $in: [
                'latest',
                'first',
              ],
            },
          },
        ]
      } else if (action === 'other') {
        query.$nor = [
          { action: 'sendprojectinvitation' },
          { action: 'postprojectinvitation' },
          { action: 'senduploadalert' },
          { action: 'sendmail' },
          { action: 'postplantag' },
          { action: 'postdocumenttag' },

          { action: 'postplan' },
          { action: 'postplanrevision' },
          { action: 'postdocument' },
          { action: 'postdocumentrevision' },
          { action: 'getplanrevisionpdf' },
          { action: 'getplanrevisiondwg' },
          { action: 'getdocumentrevision' },
          { action: 'sendrevisiondoc' },
          { action: 'sendrevisionplan' },
          { action: 'postapproval' },
          { action: 'postdocumentapproval' },
          { action: 'patchplanrevisionapproval' },
          { action: 'patchdocumentrevisionapproval' },
          { action: 'putapproveplanrevision' },
          { action: 'putapprovedocumentrevision' },
          { action: 'postapprovalcomment' },
          { action: 'postdocumentapprovalcomment' },
          { action: 'getapprovalcommentattachment' },
          { action: 'getdocumentapprovalcommentattachment' },
          { action: 'putprojectparticipantcanapproverevisions' },

          // no tasks activities
          { action: 'posttaskcomment' },
          { action: 'patchtaskfiles' },
          { action: 'patchtaskassigneeuserid' },
          { action: 'patchtaskcommentmessage' },
          { action: 'patchtaskcommentfiles' },
          { action: 'patchtaskdescription' },
          { action: 'patchtaskduedate' },
          { action: 'patchtasklocation' },
          { action: 'posttaskpublished' },
          { action: 'patchtasktitle' },
          { action: 'patchtasktrashed' },
          { action: 'patchtasktype' },

          // no photos activities
          { action: 'postphoto' },
          { action: 'patchphototitle' },
          { action: 'patchphotodescription' },
          { action: 'patchphotoauthor' },
          { action: 'patchphotolocation' },
          { action: 'patchphototrashed' },
          { action: 'patchphotoalbum' },
          { action: 'patchphotofile' },
        ]
      } else if (action === 'access') {
        const participant = window.planfredOffline.project.participants.find(p => p.user._id === targetedUserId)
        query.$or = [
          {
            action: { $in: ['putprojectparticipantpermission', 'putprojectparticipantenabled'] },
            'log.userstargetedbyaction.0': targetedUserId,
          },
          {
            action: 'postparticipant',
            'log.participant._id': participant._id,
          },
        ]
      } else if (action === 'all' || !action) {
        query.$nor = [
          { action: 'sendprojectinvitation' },
          { action: 'postprojectinvitation' },
          { action: 'senduploadalert' },
          { action: 'sendmail' },
          { action: 'postplantag' },
          { action: 'postdocumenttag' },

          // We want to exclude download actions, which are not first downloads
          {
            action: {
              $in: [
                'getplanrevisionpdf',
                'getplanrevisiondwg',
                'getdocumentrevision',
                'getdocumentapprovalcommentattachment',
                'getapprovalcommentattachment',
              ],
            },
            'log.downloadSeriesPosition': {
              $in: [
                'between',
              ],
            },
          },
        ]

        // We don't handle task activity permissions (assigneeRole, creatorRole) here (like we do it in the activity/get-all handler), because backup-app can only be generated by admins/owners
      }

      // activity log for specific user
      if (userId && (action === 'receive')) {
        query['log.userstargetedbyaction'] = {
          $elemMatch: {
            _id: userId,
          },
        }
      } else if (userId && (action === 'all')) {
        query.$or = [
          {
            'log.userstargetedbyaction': {
              $elemMatch: {
                _id: userId,
              },
            },
          },
          {
            user: userId,
          },
        ]
      } else if (userId) {
        query.user = userId
      }

      // uses the great siftjs mongoDB query simulation library
      // https://github.com/crcn/sift.js

      data = window.planfredOffline.activities.filter(sift(query))
    } else {
      console.error(`No match for url "${config.url}"`)
    }

    return Promise.resolve({
      data: JSON.parse(JSON.stringify(data || {})),
      status: 200,
      statusText: 'OK',
      headers: {},
      config,
      request: {},
    })
  }
}

export default isBackupAppInterceptor
