import constants from '../../../../client-shared/config/constants'

const CATEGORIES = constants.VALID_ACTIVITY_CATEGORIES

const isAdminOrOwner = (participant) => participant.permission === 'admin' || participant.permission === 'owner'

const getFilterUserIds = ({ project, participantRoles, participantUserIds }) => {
  let userIds = []

  if (participantUserIds.length) {
    for (const id of participantUserIds) {
      userIds.push(id)
    }
  }

  if (participantRoles.length) {
    const rolesUserIds = project.participants
      .filter(p => participantRoles.includes(p.roles[0])).map(p => p.user._id.toString())

    userIds = [...userIds, ...rolesUserIds]
  }

  const filterUserIds = [...new Set(userIds.map(id => id.toString()))]

  return filterUserIds
}
// category === 'uploads'
const buildFilterUploads = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'postplan',
        'postplanrevision',
        'postdocument',
        'postdocumentrevision',
      ],
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'downloads'
const buildFilterDownloads = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'getplanrevisionpdf',
        'getplanrevisiondwg',
        'getdocumentrevision',
        'getdocumentapprovalcommentattachment',
        'getapprovalcommentattachment',
      ],
    },
    'log.downloadSeriesPosition': {
      $ne: 'between',
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'sent'
const buildFilterSent = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'sendrevisiondoc',
        'sendrevisionplan',
        'sendmixed',
      ],
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter['log.senderUser._id'] = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'receive'
const buildFilterReceive = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'sendrevisiondoc',
        'sendrevisionplan',
      ],
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    const emails = []

    for (const id of filterUserIds) {
      const participant = project.participants.find(p => p.user._id === id)
      if (participant) {
        emails.push(participant.user.email)
      }
    }

    filter['log.mailSendResultList.recipientemail'] = {
      $in: emails,
    }
  }

  return filter
}

// category === 'access'
const buildFilterAccess = ({ project, participantUserIds, participantRoles }) => {
  const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

  return {
    action: {
      $in: [
        'putprojectparticipantpermission',
        'putprojectparticipantenabled',
      ],
    },
    'log.userstargetedbyaction': {
      $in: filterUserIds,
    },
  }
}

// category === 'login'
const buildFilterLogin = ({ project, participantUserIds, participantRoles }) => {
  const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })
  const participantIds = filterUserIds.map(id => {
    return project.participants.find(p => p.user._id === id)?._id
  })

  return {
    action: 'postparticipant',
    'log.participant._id': {
      $in: participantIds,
    },
  }
}

// category === 'approval'
const buildFilterApproval = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    $or: [
      {
        action: {
          $in: [
            'postapproval',
            'postdocumentapproval',
            'putapproveplanrevision',
            'putapprovedocumentrevision',
            'patchplanrevisionapproval',
            'patchplanrevisionapprovaldate',
            'patchdocumentrevisionapproval',
            'patchdocumentrevisionapprovaldate',
            'postapprovalcomment',
            'postdocumentapprovalcomment',
            'putprojectparticipantcanapproverevisions',
          ],
        },
      },
      {
        action: {
          $in: [
            'getapprovalcommentattachment',
            'getdocumentapprovalcommentattachment',
          ],
        },
        'log.downloadSeriesPosition': {
          $ne: 'between',
        },
      },
    ],
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'tasks'
const buildFilterTasks = ({ currentUserParticipant, project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'posttaskcomment',
        'patchtaskassigneeuserid',
        'patchtaskcommentmessage',
        'patchtaskcommentfiles',
        'patchtaskdescription',
        'patchtaskduedate',
        'patchtasklocation',
        'posttaskpublished',
        'patchtasktitle',
        'patchtasktrashed',
        'patchtasktype',
        'patchtaskfiles',
        'sendtaskreminder',
      ],
    },
  }

  if (!isAdminOrOwner(currentUserParticipant)) {
    filter.$or = [{
      'log.rollingTask.assigneeRole': currentUserParticipant.roles[0],
    },
    {
      'log.rollingTask.creatorRole': currentUserParticipant.roles[0],
    }]
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'photos'
const buildFilterPhotos = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $in: [
        'postphoto',
        'patchphototitle',
        'patchphotodescription',
        'patchphotoauthor',
        'patchphotolocation',
        'patchphototrashed',
        'patchphotoalbum',
        'patchphotofile',
      ],
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// category === 'other'
const buildFilterOther = ({ project, participantRoles, participantUserIds }) => {
  const filter = {
    action: {
      $nin: [
        'sendprojectinvitation',
        'postprojectinvitation',
        'senduploadalert',
        'sendtaskreport',
        'sendmail',
        'download',
        'postplantag',
        'postdocumenttag',

        'posttaskcomment',
        'patchtaskassigneeuserid',
        'patchtaskcommentmessage',
        'patchtaskcommentfiles',
        'patchtaskdescription',
        'patchtaskduedate',
        'patchtasklocation',
        'posttaskpublished',
        'patchtasktitle',
        'patchtasktrashed',
        'patchtasktype',
        'patchtaskfiles',

        'postphoto',
        'patchphototitle',
        'patchphotodescription',
        'patchphotoauthor',
        'patchphotolocation',
        'patchphototrashed',
        'patchphotoalbum',
        'patchphotofile',

        'postplan',
        'postplanrevision',
        'postdocument',
        'postdocumentrevision',
        'getplanrevisionpdf',
        'getplanrevisiondwg',
        'getdocumentrevision',
        'sendrevisiondoc',
        'sendrevisionplan',
        'postapproval',
        'postdocumentapproval',
        'putapproveplanrevision',
        'putapprovedocumentrevision',
        'patchplanrevisionapproval',
        'patchplanrevisionapprovaldate',
        'patchdocumentrevisionapproval',
        'patchdocumentrevisionapprovaldate',
        'postapprovalcomment',
        'postdocumentapprovalcomment',
        'getapprovalcommentattachment',
        'getdocumentapprovalcommentattachment',
        'putprojectparticipantcanapproverevisions',
        'putparticipantweekstartreport',
        'putparticipantplandocreport',
        'putparticipanttaskreport',
        'putparticipantduedatereport',
      ],
    },
  }

  if (participantRoles.length || participantUserIds.length) {
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })

    filter.user = {
      $in: filterUserIds,
    }
  }

  return filter
}

// no category
const buildFilterAll = ({ currentUserParticipant, participantUserIds, participantRoles, project }) => {
  const filter = {}

  if (participantRoles.length || participantUserIds.length) {
    const $or = []
    const filterUserIds = getFilterUserIds({ project, participantRoles, participantUserIds })
    $or.push({ user: { $in: filterUserIds } })

    $or.push({ 'log.senderUser._id': { $in: filterUserIds } })
    $or.push({ 'log.mailSendResultList.userId': { $in: filterUserIds } })

    $or.push({ 'log.rollingTask.assigneeUserId': { $in: filterUserIds } })
    $or.push({ 'log.rollingTask.creatorUserId': { $in: filterUserIds } })

    filter.$or = $or
  }

  filter.$nor = [
    {
      action: {
        $in: [
          'sendprojectinvitation',
          'postprojectinvitation',
          'senduploadalert',
          'sendtaskreport',
          'sendmail',
          'download',
          'postplantag',
          'postdocumenttag',
          'putparticipantweekstartreport',
          'putparticipantplandocreport',
          'putparticipanttaskreport',
          'putparticipantduedatereport',
        ],
      },
    },

    // We want to exclude download categories, which are not first or latest downloads
    {
      action: {
        $in: [
          'getplanrevisionpdf',
          'getplanrevisiondwg',
          'getdocumentrevision',
          'getdocumentapprovalcommentattachment',
          'getapprovalcommentattachment',
        ],
      },
      'log.downloadSeriesPosition': {
        $in: [
          'between',
        ],
      },
    },
  ]

  if (!isAdminOrOwner(currentUserParticipant)) {
    filter.$nor.push(
      // We want to EXCLUDE task activities, where user has NO creator AND NO assignee role (only admins and owners can see all the activities)
      {
        action: {
          $in: [
            'posttaskcomment',
            'patchtaskassigneeuserid',
            'patchtaskcommentmessage',
            'patchtaskcommentfiles',
            'patchtaskdescription',
            'patchtaskduedate',
            'patchtasklocation',
            'posttaskpublished',
            'patchtasktitle',
            'patchtasktrashed',
            'patchtasktype',
            'patchtaskfiles',
          ],
        },
        $nor: [
          {
            'log.rollingTask.assigneeRole': currentUserParticipant.roles[0],
          },
          {
            'log.rollingTask.creatorRole': currentUserParticipant.roles[0],
          },
        ],
      },
    )
  }

  return filter
}

// query builder for activities/POST backup app interceptor
const buildQueryActivities = ({ project, reqQuery, reqUser }) => {
  const {
    participantUserIds = [],
    participantRoles = [],
    categories = [],
    startDate,
    endDate,
  } = reqQuery

  const query = {}
  const $or = []

  if (startDate || endDate) {
    const startDateParsed = Date.parse(startDate) || new Date(0)
    const endDateParsed = Date.parse(endDate) || new Date()

    const date = {
      $gte: new Date(startDateParsed).toISOString(),
      $lte: new Date(endDateParsed).toISOString(),
    }

    query.date = date
  }

  // UPLOADS
  if (categories?.includes(CATEGORIES.UPLOADS)) {
    $or.push(buildFilterUploads({ project, participantRoles, participantUserIds }))
  }

  // DOWNLOADS
  if (categories?.includes(CATEGORIES.DOWNLOADS)) {
    $or.push(buildFilterDownloads({ project, participantRoles, participantUserIds }))
  }

  // SENT
  if (categories?.includes(CATEGORIES.SENT)) {
    $or.push(buildFilterSent({ project, participantRoles, participantUserIds }))
  }

  // RECEIVE
  if (categories?.includes(CATEGORIES.RECEIVE)) {
    $or.push(buildFilterReceive({ project, participantUserIds, participantRoles }))
  }

  // ACCESS AND LOGIN
  if (categories?.includes(CATEGORIES.ACCESS) ||
    categories?.includes(CATEGORIES.LOGIN)) {
    $or.push(buildFilterAccess({ project, participantUserIds, participantRoles }))
    $or.push(buildFilterLogin({ project, participantUserIds, participantRoles }))
  }

  // APPROVAL
  if (categories?.includes(CATEGORIES.APPROVAL)) {
    $or.push(buildFilterApproval({ project, participantUserIds, participantRoles }))
  }

  // TASKS
  if (categories?.includes(CATEGORIES.TASKS)) {
    const currentUser = reqUser
    const currentUserParticipant = project.participants
      .find(p => p.user._id.toString() === currentUser._id.toString())

    $or.push(buildFilterTasks({ currentUserParticipant, project, participantRoles, participantUserIds }))
  }

  // PHOTOS
  if (categories?.includes(CATEGORIES.PHOTOS)) {
    $or.push(buildFilterPhotos({ project, participantRoles, participantUserIds }))
  }

  // OTHER
  if (categories?.includes(CATEGORIES.OTHER)) {
    $or.push(buildFilterOther({ project, participantUserIds, participantRoles }))
  }

  // ALL with ids - When no action is sent we return all activities
  if (!categories?.some(c => Object.values(CATEGORIES).includes(c))) {
    const currentUserParticipant = project.participants
      .find(p => p.user._id.toString() === reqUser._id.toString())

    $or.push(buildFilterAll({ project, currentUserParticipant, participantUserIds, participantRoles }))
  }

  // enforce projectid to ensure ACL
  query.projectid = project._id.toString()

  if ($or.length > 0) {
    query.$or = $or
  }

  // console.dir(query)

  return query
}

export default buildQueryActivities
